html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


* {
  margin:     0;
  padding:    0;
  box-sizing: border-box;
}


html {
  --iuvia-main-color:              #3dbfc1;
  --iuvia-dark-color:              #0095a3;
  --iuvia-selected-color:          #05828e;
  --iuvia-grey-200-blend:          hsl(182, 0%, 20%);
  --iuvia-grey-500-blend:          hsl(182, 0%, 50%);
  --iuvia-grey-750-blend:          hsl(182, 0%, 75%);
  --iuvia-main-hue:                181;
  --iuvia-main-sat:                52%;
  --iuvia-menu-color:              #fff;
  --iuvia-menu-highlight:          #fff;
  --iuvia-scrolled-menu-color:     #000;
  --iuvia-scrolled-menu-highlight: #000;
}

body {
  font-family:    'Work Sans', sans-serif;
  color:          var(--iuvia-grey-200-blend);
  font-size:      12.5pt;
  font-weight: 500;
  width: 100vw;
  overflow-x: hidden;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
}

a,
a:active {
  color: var(--iuvia-grey-200-blend);
}
