html {
  --iuvia-main-color: #3dbfc1;
  --iuvia-selected-color: #05828e;
  --iuvia-grey-500-blend: hsl(182, 0%, 50%);
  --iuvia-main-hue: 181;
  --iuvia-main-sat: 52%;
}

#surveyContainer textarea.form-control, #surveyContainer input.form-control {
    background: #f5f5f5;
}

#surveyContainer input.btn.sv_next_btn, #surveyContainer input.btn.sv_start_btn, #surveyContainer input.btn.sv_complete_btn {
    background-color: var(--iuvia-main-color);
    color: #f5f5f5;
}

#surveyContainer input.btn.sv_start_btn {
    padding-left: 4em;
    padding-right: 4em;
}

#surveyContainer input.btn.sv_prev_btn {
    background-color: hsl(182, 0%, 70%);
    color: #fff !important;
}

#surveyContainer input.btn.sv_prev_btn:hover {
    background-color: #888 !important;
    color: #fff !important;
}

#surveyContainer div.progress-bar > span {
    background: transparent;
    position: absolute;
    color: #fff;
    width: 26em;
    display: block;
    overflow: visible;
}

.survey #fh5co-footer {
    position: initial;
}

.survey .panel-footer {
    background: inherit;
    text-align: center;
}


/* Progress bar fixed on top */
.progress-bar {
    border-radius: 0px 30px 30px 0px;
}
.progress-bar span {
    line-height: 30px;
}

.sv_main.sv_main .progress.center-block {
    position: sticky;
    top:0;
    left:0;
    width: 100%;
    z-index: 10;
    height: 30px;
}
#surveyContainer {
    margin-top: 30px;
}

.btn {
    border-radius: 5px;
    font-size: 14px;
}
