* {
    margin:     0;
    padding:    0;
    box-sizing: border-box;
}


html {
    --iuvia-main-color:              #3dbfc1;
    --iuvia-dark-color:              #0095a3;
    --iuvia-selected-color:          #05828e;
    --iuvia-grey-200-blend:          hsl(182, 0%, 20%);
    --iuvia-grey-500-blend:          hsl(182, 0%, 50%);
    --iuvia-grey-750-blend:          hsl(182, 0%, 75%);
    --iuvia-main-hue:                181;
    --iuvia-main-sat:                52%;
    --iuvia-menu-color:              #fff;
    --iuvia-menu-highlight:          #fff;
    --iuvia-scrolled-menu-color:     #000;
    --iuvia-scrolled-menu-highlight: #000;
}

body {
    font-family:    'Work Sans', sans-serif;
    color:          var(--iuvia-grey-200-blend);
    font-size:      12.5pt;
    font-weight: 500;
    width: 100vw;
    overflow-x: hidden;
}

a,
a:hover,
a:active,
a:visited {
    text-decoration: none;
}

a,
a:active {
    color: var(--iuvia-grey-200-blend);
}

section.hero-banner {
    margin-top: -66px;
    padding-top: 66px;
    padding-bottom: 0;
    height: max(100%, 100vh);
}

section {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

h1,
h2,
h3,
h4 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
}

h1,
h2 {
    text-align:     center;
    margin-bottom:  2rem;
    font-size:      45px;
}

@media screen and (max-width: 768px) {
h1, h2 {
    font-size: 35px;
}
.text-hero {
    font-size: 9px;
}
}

.container {
    width:  clamp(360px, 90%, 1130px);
    margin: auto;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
.container {
    width:  95%;
    margin: auto;
    justify-content: space-between;
}
}

.card {
    font-size: .95em;
}



nav.navbar {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100vw;
    padding: 8px;
    box-shadow: 0 2px 2px 0 rgba(51,51,51,.2);
    z-index: 1500;
}

nav.navbar img {
    height: 35px;
}


@media screen and (max-width: 768px) {
    nav.navbar img {
        height: 7vw;
    }

    nav.navbar .btn {
        margin-right: 0!important;
    }
}

@media screen and (max-width: 375px) {
    nav.navbar {
        font-size: clamp(6pt, 4vw, 12pt);
    }
}

nav.navbar .btn {
    padding: 0.5em;
}

.container p {
    color: hsl(182, 0%, 50%);
    font-weight: 400;
}

.container .dark p, .container.dark p {
    color: hsl(182, 0%, 90%);
    font-weight: 400;
}

.nojs nav.navbar {
    opacity: 1;
}

.js nav.navbar {
    opacity: 0;
}

nav.navbar > .container {
    display: flex;
    height: 50px;
}

nav.navbar > .container * {
    display: flex;
    max-height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 6px;
    margin-right: 6px;
}

.language-selector-menu-hover .language-active {
    color: #888;
}

.language-selector::after {
    content: "\f078";
    font-family: 'icomoon';
    padding-left: 3pt;
}

.language-selector.visible::after {
    content: "\f077";
    font-family: 'icomoon';
    padding-left: 3pt;
}

nav.navbar > .container > .left {
    display: flex;
    height: 50px;
}
nav.navbar > .container > .right {
    display: flex;
    height: 50px;
}

.text-hero .begin {
    margin-bottom: 0;
}

.text-hero .align-left {
    text-align: left;
}

.text-hero .align-right {
    text-align: right;
    margin-bottom: 2em;
}

.text-hero .align-center {
    text-align: center;
}

.text-hero p {
    font-size: 2em;
    font-weight: 400;
    color: hsl(182, 0%, 10%);

}

.text-hero p em {
    font-style: normal;
    font-weight: 600;
    color: var(--iuvia-dark-color);
}

.hero-banner {
    /*
    --iuvia-main-color:              #3dbfc1; ## rgba(61, 161, 193, 1)
    --iuvia-dark-color:              #0095a3; ## rgba(0, 149, 163, 1)
    --iuvia-selected-color:          #05828e;
    */
    background: linear-gradient(-37deg, #05828e, #3dbfc1);
    background-size:  cover;
    background-position: bottom;
    padding-top:      4rem;
    color:            white;
}

.hero-banner h1 {
    font-size: max(16pt, 2.6vw);
}

.full-brand img {
    width: min(400px, 60%);
}

@media only screen and (max-width: 767px) {
    .full-brand { display: block; }
    .full-brand img { width: min(400px, 60%) }
}

.full-brand h4 {
    font-size: 30%;
}

.full-brand {
    margin-top:    0.5rem;
    margin-bottom: 2rem;
}

.intro-left-text p {
    font-size: 1.1em;
    font-weight: 500;
    padding-bottom: 0.5em;
    text-align: left;
}

.hero-banner .flex-v {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: space-evenly;
    column-gap: 3em;
}

.flex-34 {
    display:         flex;
    flex-wrap:       wrap;
    justify-content: center;
    align-items:     center;
    column-gap:      3em;
}

section.unfold-video-section {
    padding: 0;
}

.match-unfold-video-bg {
    background-color: #f7f7f7;
}

.match-unfold-video-bg-after, section[data-section=what-inside] ~ section {
    background-color: #ebebeb;
}

.flex-34 > *:nth-child(1) {
    flex: 1 1 200px;
}

.flex-34 > *:nth-child(2) {
    flex: 4 1 500px;
}

.intro-right-video {
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}
.intro-right-video p {
    margin: auto;
}

.features-list {
    text-align: center;
}

.card-list {
    display: flex;
    flex-wrap: wrap;
    align-items: first baseline;
    justify-content: center;
}

.features-list .card {
    display: inline-block;
    padding: 20px;
    text-align: center;
    width: 20em;
}

.features-list .card img {
    width: 128px;
}

.middle-feat img {
    width: min(100%, 512px);
    object-fit: contain;
}

*[data-section=main-values] .values-list {
    display: flex;
    flex-wrap: wrap;
}

.values-list .card {
    display: inline-block;
    padding: 20px;
    text-align: center;
    width: 20em;
}

.values-list .card img {
    height: 128px;
}


.hero-2 {
    background: url(/images/background/intro-v2.jpg);
    background-size:  cover;
    background-position: bottom;
    padding-top:      4rem;
    color:            white;
}

.hero-2 h2 {
    font-weight: 400;
    font-size: 2em;
}

@media only screen and (max-width: 767px) {
.hero-2 h2 { display: inline; }
.hero-2 { text-align: justify; }
}

.hero-2 h2 em {
    font-weight: 600;
    font-style: normal;
    font-size: 45px;
    opacity: 1;
}

.hero-2 h2:nth-child(1) {
    text-align: left;
}

.hero-2 h2:nth-child(2) {
    text-align: right;
}

.hero-2 .hero-separator {
    height: 140px;
}

.grid-2 {
    margin-top: 3em;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    gap: 0em;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .grid-2 {
        grid-template-columns: 0.5fr 0.5fr;
    }
}

@media only screen and (max-width: 767px) {
    .grid-2 {
        grid-template-columns: 1fr;
    }
}

.grid-2 .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 0px 1.6px rgba(0,0,0,.14), 0 1px 0px -2px rgba(0,0,0,.2), 0 1px 2px 0 rgba(0,0,0,.12);
    margin: 0 1em 2em 1em;
    padding: 1.5em;
}

.card .icon {
    width: 80px;
    height: 80px;
    display: block;
    display: table;
    text-align: center;
    margin: 0 auto;
    position: relative;
    border: 1px solid var(--iuvia-main-color);
    border-radius: 50%;
    transition: .3s;
    color: var(--iuvia-main-color);
}

.card .icon i {
    display: table-cell;
    vertical-align: middle;
    font-size: 50px;
}

.card:hover .icon {
    color: white;
    background-color: var(--iuvia-main-color);
}


.grid-2 .card h3 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}
.grid-2 .card p {
    text-align: center;
    margin-bottom: 0.5em;
}
.grid-2 .card img {
    padding-top: 0;
    padding-bottom: 10px;
    width: 100%;
    object-fit: contain;
    height: 80px;
}

*[data-section=onboarding] video {
    object-fit: cover;
    width: 100vw;
    height: 50vh;
}

.feature {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}

.feature h3 {
    font-size: 2rem;
    margin-bottom: 0.3em;
}

.feature p {
    margin-bottom: 0.5em;
}

.blackbg {
    background-color: #000;
}

.blackbg h2, .blackbg h2, .blackbg p {
    color: #DEDEDE;
}

.img-responsive {
    width: 100%;
}

.responsive-container {
    width: 100vw;
    height: calc(100vh - 50px);
    z-index: 1;
    overflow: hidden;
}

.responsive-container > * {
    margin-left: 50vw;
    transform: translateX(-50%);
}

@media screen and (max-aspect-ratio: 1920/1080) {
.responsive-container > * {
    height: 100%;
}
}
@media screen and (min-aspect-ratio: 1920/1080) {
.responsive-container > * {
    width: 100%;
}
}


.tablet-hidden {
    display: inherit;
}
.tablet-shown {
    display: none;
}
@media only screen and (max-width: 767px) {
.tablet-hidden { display: none; }
.tablet-shown { display: inherit; }
}

#unfold-trigger {
    background-color: #f7f7f7;
}


.grid-right-leaning {
    display:               grid;
    grid-template-columns: repeat(auto-fit, minmax(3fr 4fr));
    grid-template-rows:    100%;
    grid-gap:              1em;
    place-items:           center;
}

.full-section {
    min-height:  max(615px, calc(100vh - 64px));
}

.full-section.flex {
    display:     flex;
    align-items: center;
}

.grid-right-leaning>* {
    margin: 1em;
}


.intro-right-video,
.intro-right-video>iframe {
    aspect-ratio: 16/9;
    width:        100%;
}

.rounded {
    border-radius: 15px 15px;
}

.centering {
    text-align: center;
}

.intro-text {
    margin-left: min(8vw, 8rem);
    margin-right: min(8vw, 8rem);
}

.intro-text p {
    margin-bottom: 1em;
}

.intro-right-video {
    box-shadow:           5px 10px 20px rgba(0, 0, 0, 0.35);
}

.cta #mauticform_wrapper_preorderlanding {
    max-width: inherit;
}

.cta #mauticform_preorderlanding .btn, .btn {
    font-size:        1em;
    display:          inline-block;
    border-radius:    1rem 1rem;
    /*box-shadow:       15px 20px 10px var(--iuvia-grey-500-blend);*/
    border:           1px solid var(--iuvia-dark-color);
    padding:          1rem;
    margin:           1rem;
    color:            white;
    text-decoration:  none;
    background-color: var(--iuvia-dark-color);
    transition:       all 0.2s ease-in-out;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
}


.cta-content {
    display: flex;
    flex-wrap: wrap;
}

.cta-content > *:nth-child(1) {
    flex: 2 1 400px;
}
.cta-content > *:nth-child(2) {
    flex: 1 2 100px;
    align-self: center;
    position: relative;
    left: 35px;
}

.cta-content .mascot {
    max-height: 250px;
    width: 100%;
    object-fit: contain;
}

.btn:hover {
    border:           1px solid var(--iuvia-selected-color);
    background-color: var(--iuvia-selected-color);
    box-shadow:       0px 0px 5px var(--iuvia-selected-color);
}

.my-auto {
    margin-top:    auto;
    margin-bottom: auto;
}


.mauticform_wrapper {
    color: #333 !important;
}

.price-card {
    margin: 3em 0 0 0;
    padding: 2em;
    display:   flex;
    gap: 4em;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 5em;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    
    /* color: hsl(182, 0%, 50%); */
    font-weight: 400;
}

.price-card .card-image {
    display: flex;
    flex: 1 1 100px;
    flex-direction: column;
    width: 30vh;
    justify-content: center;
    text-align: center;
}

.price-card img {
    height: 10em;
    object-fit: contain;
}

.price-card h4 {
    color: var(--iuvia-main-color);
    font-size: 2rem;
}

.price-card .card-content {
    flex: 2 2 200px;
    align-self: center;
}

.price-card ul > li {
    margin-top: 10px;
}


.cta {
    border-radius: 10px;
    padding: 3em;
    position: relative;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

@media screen and (max-width: 768px) {
.cta {
    padding: 3em 1em 1em 1em;
}
}

.cta.dark .mauticform_wrapper {
    max-width: auto;
}

.cta.dark .mauticform_wrapper {
    color: white !important;
}

.cta #mauticform_preorderlanding .mauticform-button-wrapper {
    text-align: center;
}

.cta.dark {
    background-color: var(--iuvia-main-color);
    color: white;
}

/* INSET */
.cta {
    margin-bottom: -10em;
}
.faq-bg {
    padding-top: 10em;
}

.faq-bg h3, .card h3 {
    font-weight: 500;
}


footer.page-footer {
    background-color: var(--iuvia-grey-200-blend);
    padding:          20px;
    color:            var(--iuvia-menu-color);
}

.faq-bg {
    background: url(/images/background/faq-bg.jpg);
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

*[data-section=faq] {
    color: #fff;
    margin-top: -0.5em;
}

*[data-section=faq] .value-item {
    border: 2px solid white;
    padding: 0em 2em 1em 2em;
    margin: -2px 0 -2px 0 !important;
    --border-radius: 10px;
}

*[data-section=faq] .value-item .value-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

*[data-section=faq] .value-item .value-title::after {
    float: right;
    color: inherit;
}

*[data-section=faq] .value-item:first-child {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}

*[data-section=faq] .value-item:last-child {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
}


.after-faq-boundary {
    height: max(250px, 35vw);
}

footer.page-footer {
    font-size: 0.75em;
}

footer.page-footer a,
footer.page-footer a:hover,
footer.page-footer a:active,
footer.page-footer a:visited {
    color:   var(--iuvia-menu-color);
}

footer.page-footer ul {
    list-style: none;
}

footer.page-footer ul>li {
    list-style: none;
}

ul.social-icons-list {
    display: block;
}

.social-icons-list > li {
    display: inline;
}
.social-icons-list > li > *, footer.page-footer .social-icons-list a {
    padding: 5px;
    font-size: 14pt;
}

footer .flex {
    display:         flex;
    flex-wrap:       wrap;
    justify-content: space-around;
    row-gap:         2em;
    align-items:     center;
    font-size: 12px;
}

footer .flex>* {
    flex: 2 0 200px;
    text-align: center;
}

footer .ngi-funding {
    flex:       3 0 200px;
    text-align: justify;
}
footer .ngi-logos {
    margin-top: 10px;
}
