@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?qtatmt');
	src:url('../fonts/icomoon/icomoon.eot?qtatmt#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.ttf?qtatmt') format('truetype'),
		url('../fonts/icomoon/icomoon.woff?qtatmt') format('woff'),
		url('../fonts/icomoon/icomoon.svg?qtatmt#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

.value-accordion {
    overflow:hidden;
    margin:10px auto;
    padding:10px;
  }
  
  .value-accordion .value-item {
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .value-item a,
  .value-item a:hover {
    text-decoration: none;
  }
  
  .value-item .value-title {
    cursor: pointer;
    margin: 20px 0 0 0;
  }
  
  /* Chevron after value-title */
  .value-item .value-title::after {
      content: "\f078";
      font-family: 'icomoon';
    font-size: 20px;
    color: var(--iuvia-main-color);
    cursor: pointer;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    margin-left: 10px;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    transition: color 0.5s;
  }
  
  .value-accordion .value-item input[type="radio"]:checked + label .value-title::after {
      content: "\f077";
  }
  
  .value-accordion .value-item input[type="radio"] {
    display: none;
  }
  
  .value-accordion .value-item input[type="radio"] + label {
    margin: 0;
  }
  
  .value-accordion .value-item input[type="radio"] + label .value-text {
    font-weight: normal;
    max-height: 0px;
    overflow: hidden;
    margin: 0;
    transition: all 0.25s ease-out;
    padding: 0;
  }
  
  .value-accordion .value-item input[type="radio"]:checked + label .value-text {
    max-height: 400px;
    margin-top: 10px;
  }
  